import React from 'react';
import '../styles/main.css';// Import your custom CSS for styling
import '../styles/responsive.css';

const FooterSection = () => {
  return (
    <footer>
      
    </footer>
  );
};

export default FooterSection;
