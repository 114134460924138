import React, { useState } from 'react';
import axios from 'axios';
import vdo from '../img/vdo.mp4';
import '../styles/main.css';
import '../styles/responsive.css';

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    budget: '',
    message: ''
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://ott.bizkranti.com/contact/save', formData);
      console.log('Response:', response.data);
      alert('Contact saved successfully!');
    } catch (error) {
      console.error('Error submitting the form:', error);
      alert('Something went wrong. Please try again later.');
    }
  };

  return (
    <section id="contact" className="section">
      <div className="container">
        <div className="row text-center mb-5">
          <div className="col-12">
            <div className="contact-text section-header">
              <h2 className="section-title text-warning fs-1" style={{
                background: "linear-gradient(90deg, rgba(200,3,70,1) 30%, rgba(249,157,64,1) 77%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent"
              }}>
                <b>Get In Touch</b>
              </h2>
              <p className="text-white">
                Ready to elevate your OTT platform? Connect with BizKranti, where innovation meets expertise. Let's bring your digital vision to life!
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-12 mb-4">
            <form id="contactForm" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <input type="text" className="form-control" name="name" placeholder="Name" value={formData.name} onChange={handleInputChange} required />
                </div>
                <div className="col-md-6 mb-3">
                  <input type="text" className="form-control" name="subject" placeholder="Subject" value={formData.subject} onChange={handleInputChange} required />
                </div>
                <div className="col-md-6 mb-3">
                  <input type="email" className="form-control" name="email" placeholder="Email" value={formData.email} onChange={handleInputChange} required />
                </div>
                <div className="col-md-6 mb-3">
                  <input type="text" className="form-control" name="budget" placeholder="Budget" value={formData.budget} onChange={handleInputChange} required />
                </div>
                <div className="col-md-12 mb-4">
                  <textarea className="form-control" name="message" placeholder="Write Message" rows="4" value={formData.message} onChange={handleInputChange} required></textarea>
                </div>
                <div className="submit-button text-center">
                  <button className="btn btn-common" type="submit">Submit</button>
                </div>
              </div>
            </form>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="contact-video">
              <video className="contact-video" autoPlay loop muted playsInline preload="auto">
                <source src={vdo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
