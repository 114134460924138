import React from 'react';
import '../styles/main.css'; // Import the CSS file
import '../styles/responsive.css';
import business from '../img/film-7149.gif';

const BusinessPlan = () => {
  return (
    <section id="business-plan">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 pl-0 pt-70 pr-5">
            <div className="business-item-img">
                <img src={business} className="img-fluid" alt="" />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 pl-4">
            <div className="business-item-info">
              <h3 class='text-white text-center'> 🚀 Unlock the Future of Streaming <b/> with BizKranti!</h3>
              <p class='text-white text-center'>Imagine having a professionally developed OTT application that not only <br /> meets but exceeds your expectations. With BizKranti, you’ll have <br /> access to the latest technologies and industry best  practices,<br /> ensuring your application is at the forefront of the market. <br /></p>
              <a className="btn btn-common text-white" href="#">download</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessPlan;
