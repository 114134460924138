import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import BusinessPlan from './components/BusinessPlan';
import Features from './components/Features';
import ClientTestimonials from './components/ClientTestimonials';
import DetailsSection from './components/DetailsSection';
import Showcase from './components/Showcase';
import PricingPlans from './components/PricingPlans';
import ContactSection from './components/ContactSection';
import Services from './components/Services';
import FooterSection from './components/FooterSection';

const App = () => {
  return (
    <div>
      <Header />
      <Services />
      <BusinessPlan />
      <Features />
      <ClientTestimonials />
      <DetailsSection/>
      <Showcase/>
      <PricingPlans />
      <ContactSection />
      <FooterSection />

    </div>
  );
};

export default App;


// import React from 'react';
// import { BrowserRouter as Router } from 'react-router-dom';
// import Layout from './components/Layout';

// const App = () => {
//   return (
//     <Router>
//       <Layout />
//     </Router>
//   );
// };

// export default App;
