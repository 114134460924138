import React, { useState, useEffect } from 'react';
import '../styles/slider.css';
import '../styles/responsive.css';

// Import images
import img1 from '../img/showcase/1.jpg';
import img2 from '../img/showcase/2.jpg';
import img3 from '../img/showcase/3.jpg';
import img4 from '../img/showcase/4.jpg';
import img5 from '../img/showcase/6.jpg';
import img6 from '../img/showcase/8.jpg';
import img7 from '../img/showcase/9.jpg';
import img8 from '../img/showcase/10.jpg';
import img9 from '../img/showcase/11.jpg';
import img10 from '../img/showcase/12.jpg';
import img11 from '../img/showcase/13.webp';
import img12 from '../img/showcase/14.jpg';

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12,];

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 2000); // Change image every 2 seconds
    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className="slider-container">
      <div className="upper-box">
        <h2 class='text-warning'><b>Are You Ready To Elevate Your OTT Application</b></h2>
        <p class='text-white fs-5'>For a limited time, BizKranti is offering an exclusive discount on our top-tier OTT application development services. Don’t miss this chance to partner with India’s leading OTT solutions provider!</p>
      </div>
      {/* <button className="prev-btn" onClick={prevSlide}>❮</button> */}
      <div className="slider">
        <div
          className="slider-wrapper"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((src, index) => (
            <div className="slide" key={index}>
              <img src={src} alt={`Slide ${index}`} />
            </div>
          ))}
        </div>
      </div>
      {/* <button className="next-btn" onClick={nextSlide}>❯</button> */}
    </div>
  );
};

export default Slider;
