import React from 'react';
import '../styles/main.css'; // Import your CSS file for additional styling if needed
import icon1 from '../img/_D_T_-removebg-preview.png';
import icon2 from '../img/svod.png';
import icon3 from '../img/admob.png';
import icon4 from '../img/tvod2.png';

const details = [
  {
    title: 'Donation / Tips (D&T)',
    description: 'Engage your loyal audience by accepting tips and donations for both live streams and video-on-demand (VOD) content. This feature provides an additional cash flow beyond subscriptions and rentals, enabling fans to directly support the content they love and fostering a deeper connection with your brand.',
    icon: icon1,
  },
  {
    title: 'Subscription (SVOD)',
    description: 'Offer your audience the convenience of a subscription-based model where they can access your content by paying a recurring fee. Tailor subscription packages to meet various preferences with options for daily, monthly, quarterly, and yearly plans. Customize further by selecting the number of devices.',
    icon: icon2,
  },
  {
    title: 'Display Ads (AdMob)',
    description: 'Generate revenue from non-paying users by integrating AdMob and other display ad exchanges. Whether you choose a purely ad-supported model or a hybrid approach that combines ads with subscriptions or rentals, you have complete control over your monetization strategy. This flexibility user satisfaction',
    icon: icon3,
  },
  {
    title: 'Pay-Per-View(TVOD)',
    description: 'Allow viewers to pay a one-time fee to either purchase or rent individual videos for a limited time. This transactional video-on-demand (TVOD) model users pay per video, providing revenue stream. Users who want to rewatch content can simply pay again,continued income your valuable offerings.',
    icon: icon4,
  },
];

const DetailsSection = () => {
  return (
    <section className="container my-5">
      <div className="row text-center">
        {details.map((detail, index) => (
          <div key={index} className="col-lg-3 col-md-6 mb-4">
            <div className="card shadow-sm p-3 detail-card h-80">
              <div className="card-body d-flex flex-column justify-content-center align-items-center">
                <img src={detail.icon} alt={`Icon for ${detail.title}`} className="detail-icon mb-3" style={{ maxWidth: '100px' }} />
                <h5 className="card-title fw-bold text-white fs-4">{detail.title}</h5> {/* Title with bold class */}
                <p className="card-text text-white">{detail.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default DetailsSection;
