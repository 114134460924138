import React from 'react';
import '../styles/main.css'; // Import the CSS file
import '../styles/responsive.css';

// Import images from the src folder
import technologyIcon from '../img/VECTORS/State-of-the-Art Technology.png';
import experienceIcon from '../img/VECTORS/EXCEPTIONAL USER EXPERIENCE.png';
import solutionsIcon from '../img/VECTORS/Tailored Solutions.png';
import deliveryIcon from '../img/VECTORS/On-Time Delivery.png';
import developmentIcon from '../img/VECTORS/Custom Development.png';
import featuresIcon from '../img/VECTORS/Advanced Features.png';

const Features = () => {
  return (
    <section id="features">
      <div className="container">
        <div className="text-center mb-4">
          <h2 class='features-h2 '><b>Services We Provide</b></h2>
          <p className='text-white'>Here’s why BizKranti is your best choice for OTT applications in India:</p>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="feature-item featured-border1">
              <div className="feature-icon">
                <img src={technologyIcon} alt="State-of-the-Art Technology" />
              </div>
              <h4 class='text-warning text-start fs-4'>State-of-Art Technology</h4>
              <p class='text-white text-start'>Leverage the latest advancements to ensure <br /> your OTT application is fast, secure, and scalable <br />for more.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="feature-item featured-border2">
              <div className="feature-icon">
                <img src={experienceIcon} alt="Exceptional User Experience" />
              </div>
              <h4 class='text-warning text-start fs-4'>User Experience</h4>
              <p class='text-white text-start'>Enjoy intuitive design and seamless navigation <br />that keeps your viewers engaged and coming back <br />for more.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="feature-item featured-border3">
              <div className="feature-icon">
                <img src={solutionsIcon} alt="Tailored Solutions" />
              </div>
              <h4 class='text-warning text-start fs-4'>Tailored Solutions</h4>
              <p class='text-white text-start'>Get a solution customized to fit your unique<br /> business needs and goals, from initial concept to <br />final launch.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="feature-item featured-border1">
              <div className="feature-icon">
                <img src={deliveryIcon} alt="On-Time Delivery" />
              </div>
              <h4 class='text-warning text-start fs-4'>On-Time Delivery</h4>
              <p class='text-white text-start'>We adhere to strict timelines to ensure your <br /> project is completed on schedule and within your<br /> budget.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="feature-item featured-border2">
              <div className="feature-icon">
                <img src={developmentIcon} alt="Custom Development" />
              </div>
              <h4 class='text-warning text-start fs-4'>Custom Development</h4>
              <p class='text-white text-start'>We build OTT applications that perfectly match <br />your vision and business goals.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="feature-item featured-border3">
              <div className="feature-icon">
                <img src={featuresIcon} alt="Advanced Features" />
              </div>
              <h4 class='text-warning text-start fs-4'>Advanced Features</h4>
              <p class='text-white text-start'>Incorporate features like personalized your <br />recommendations, multi-device support, and more.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
