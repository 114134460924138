import React from 'react';
import '../styles/Header.css'; // Import the CSS file
import tvImage from '../img/feature-3.png'; // Import the TV image
import video from '../img/video1.m4v';
import logo from '../img/ott.png'; // Import the Logo image'


const Header = () => {
  return (
    <header id="home" className="hero-area">    
      <div className="overlay">
        <span></span>
        <span></span>
      </div>
      <nav className="navbar navbar-expand-md navbar-dark fixed-top scrolling-navbar">
        <div className="container">
          <a href="#home" className="navbar-brand">
            <img src={logo} alt="Logo" className="logo" />
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav ml-auto w-100 justify-content-end">
              <li className="nav-item">
                <a className="nav-link page-scroll text-white" href="#home">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link page-scroll text-white" href="#services">About</a>
              </li>  
              <li className="nav-item">
                <a className="nav-link page-scroll text-white" href="#features">Services</a>
              </li>                            
              <li className="nav-item">
                <a className="nav-link page-scroll text-white" href="#showcase">Showcase</a>
              </li>       
              <li className="nav-item">
                <a className="nav-link page-scroll text-white" href="#pricing">Pricing</a>
              </li>     
              <li className="nav-item">
                <a className="nav-link page-scroll text-white" href="#contact">Contact</a>
              </li> 
            </ul>
          </div>
        </div>
      </nav>  
      <div className="container">      
        <div className="row space-100">
          <div className="col-lg-6 col-md-12 col-xs-12">
            <div className="contents">
              <h2 className="head-title text-white">Transform Your OTT Vision into <br />Reality with BizKranti🚀</h2>
              <p className='text-white'>Transform Your Streaming Business with BizKranti’s Special Offer! 🎉</p>
              <div className="header-button">
                <a href="https://" rel="nofollow" target="_blank" className="btn btn-danger text-center align-item-center">Purchase Now</a>
                <a href="https://" rel="nofollow" target="_blank" className="btn btn-danger text-center">Learn More</a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-xs-12 p-0">
            <div className="intro-img">
              <div className="tv-container">
                <video className="background-video" autoPlay loop muted>
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <img src={tvImage} alt="TV" className="tv-image" />
              </div>
            </div>            
          </div>
        </div> 
      </div>             
    </header>
  );
};

export default Header;
