import React from 'react';
import '../styles/main.css'; // Make sure to create this CSS file
import '../styles/responsive.css';

const ClientTestimonials = () => {
  return (
    <section id="testimonial" className="section">
      <div className="container right-position">
        <div className="row">
          <div className="col-md-12 col-sm-12">
          <div className="video-promo-content text-center">
            <iframe id="promo-video" width="1000" height="430" src="https://www.youtube.com/embed/MNW4vv45YcY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          </div>
        </div>
        <div className="row justify-content-center testimonial-area">
          <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12 pr-20 pl-20" style={{ paddingBottom: '60px'}}>
            <div id="client-testimonial" className="text-center owl-carousel">
              <div className="item">
                <div className="testimonial-item">
                  <div className="content-inner">
                    <p className="description text-white">At BizKranti, we specialize in creating cutting-edge OTT applications tailored <br /> to your needs. Our expert team brings innovation and efficiency to  every  <br />project, ensuring you stand out in the competitive OTT landscape </p>
                    <div className="author-info">
                      <h5 class='text-white'>BizKranti OTT Platform</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientTestimonials;


