import React from 'react';
import '../styles/main.css'; // Make sure to create this CSS file
import '../styles/responsive.css';

const PricingPlans = () => {
  return (
    <section id="pricing" className="section">
      <div className="container1">
        <div className="row">
          <div className="col-lg-12">
            <div className="pricing-text section-header text-center">
              <div>
                <h2 className="section-title text-warning fs-1" style={{ background: "linear-gradient(90deg, rgba(200,3,70,1) 30%, rgba(249,157,64,1) 77%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }}>
                  <b>Pricing plans</b>
                </h2>
                <div className="desc-text">
                  <p className='text-white'>🎉 Transform Your Streaming Business with BizKranti’s Special Offer! 🎉</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pricing-tables">
          <div className="col-lg-4 col-md-4 col-xs-12">
            <div className="pricing-table text-center">
              <div className="pricing-details">
                <h3 className='text-warning'><b> Basic <span style={{color: '#ddd' }}> Package</span></b></h3>
                <h1><span>$</span>149999</h1>
                <ul className='text-white list-unstyled'>
                  <li className='text-white text-start d-flex align-items-start' style={{ gap: '10px' }}>
                    <div>◉</div>
                    <div>Custom OTT website landing page and mobile app development (basic design) User registration and login</div>
                  </li>
                  <li className='text-white text-start d-flex align-items-start' style={{ gap: '10px' }}>
                    <div>◉</div>
                    <div>Basic content management system (CMS)</div>
                  </li>
                  <li className='text-white text-start d-flex align-items-start' style={{ gap: '10px' }}>
                    <div>◉</div>
                    <div>Payment gateway integration for one-time purchases (if needed) Basic analytics views, watch time</div>
                  </li>
                  <li className='text-white text-start d-flex align-items-start' style={{ gap: '10px' }}>
                    <div>◉</div>
                    <div>Ideal For: Startups or small content creators looking to launch a simple OTT platform with core functionality.</div>
                  </li>
                  <li className='text-white text-start d-flex align-items-start' style={{ gap: '10px' }}>
                    <div>◉</div>
                    <div>Basic Content Management System (CMS): The package includes a basic CMS, enabling users to manage and update content easily.</div>
                  </li>
                </ul>
              </div>
              <div className="plan-button">
                <a href="#" className="btn btn-border">Purchase</a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-xs-12">
            <div className="pricing-table text-center">
              <div className="pricing-details">
                <h3 className='text-warning'><b>Standard <span style={{color: '#ddd'}}>Package</span></b></h3>
                <h1><span>$</span>399999</h1>
                <ul className='text-white list-unstyled'>
                  <li className='text-white text-start d-flex align-items-start' style={{ gap: '10px' }}>
                    <div>◉</div>
                    <div>Custom OTT website landing page and mobile app development User management (registration, subscription plans, profiles)</div>
                  </li>
                  <li className='text-white text-start d-flex align-items-start' style={{ gap: '10px' }}>
                    <div>◉</div>
                    <div>Advanced content management system (CMS) with scheduling features Payment gateway integration (subscriptions + one-time purchases) Multi-language support (up to 3 languages)</div>
                  </li>
                  <li className='text-white text-start d-flex align-items-start' style={{ gap: '10px' }}>
                    <div>◉</div>
                    <div>Advanced analytics dashboard</div>
                  </li>
                  <li className='text-white text-start d-flex align-items-start' style={{ gap: '10px' }}>
                    <div>◉</div>
                    <div>Push notifications and email alerts</div>
                  </li>
                  <li className='text-white text-start d-flex align-items-start' style={{ gap: '10px' }}>
                    <div>◉</div>
                    <div>Ideal For: Medium-sized businesses or content creators who want to offer subscriptions, enhanced features, and grow their platform.</div>
                  </li>
                  <li className='text-white text-start d-flex align-items-start' style={{ gap: '10px' }}>
                    <div>◉</div>
                    <div>Sales Dashboard</div>
                  </li>
                </ul>
              </div>
              <div className="plan-button">
                <a href="#" className="btn btn-border">Purchase</a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-xs-12">
            <div className="pricing-table text-center">
              <div className="pricing-details">
              <h3 className='text-warning'><b>Premium <span style={{color: '#ddd'}}>Package</span></b></h3>
                <h1><span>$</span>899999</h1>
                <ul className='text-white list-unstyled'>
                  <li className='text-white text-start d-flex align-items-start' style={{ gap: '10px' }}>
                    <div>◉</div>
                    <div>Fully custom OTT website and mobile app development both android and ios</div>
                  </li>
                  <li className='text-white text-start d-flex align-items-start' style={{ gap: '10px' }}>
                    <div>◉</div>
                    <div>Premium UI/UX design</div>
                  </li>
                  <li className='text-white text-start d-flex align-items-start' style={{ gap: '10px' }}>
                    <div>◉</div>
                    <div>User management with advanced subscription models Advanced content management system (CMS)</div>
                  </li>
                  <li className='text-white text-start d-flex align-items-start' style={{ gap: '10px' }}>
                    <div>◉</div>
                    <div>Payment gateway integration (subscriptions, one-time purchases, ad monetization)</div>
                  </li>
                  <li className='text-white text-start d-flex align-items-start' style={{ gap: '10px' }}>
                    <div>◉</div>
                    <div>Advanced analytics and personalized recommendations, user retention, Live streaming integration (if needed)</div>
                  </li>
                  <li className='text-white text-start d-flex align-items-start' style={{ gap: '10px' }}>
                    <div>◉</div>
                    <div>Push notifications, email alerts, and in-app messaging</div>
                  </li>
                </ul>
              </div>
              <div className="plan-button">
                <a href="#" className="btn btn-border">Purchase</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingPlans;
