import React from 'react';
import '../styles/main.css'; // Import the CSS file
import '../styles/responsive.css';

// Importing images
import subscriptionIcon from '../img/Wide Platform Availability.jpg';
import adsIcon from '../img/Customizable Media Player.webp';
import payPerViewIcon from '../img/Affordable Pricing Options.jpg';
import newServiceIcon from '../img/Enhanced Viewer Engagemen.webp'; // Replace with your actual image path

const Services = () => {
  return (
    <div className="bg-black">
      <section id="services" className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-xs-12">
              <div className="services-item text-center bg-black">
                <div className="icon bg-black">
                  <img src={subscriptionIcon} alt="Subscription Icon" className="icon-ima"/>
                </div>
                <h4 className="text-white">Wide Platform Availability:</h4>
                <p className="text-white">Distribute your content seamlessly across mobile, web, desktop, TV, and smart boxes to reach a broader audience and boost your revenue.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12">
              <div className="services-item text-center">
                <div className="icon">
                  <img src={adsIcon} alt="Ads Icon" className="icon-ima"/>
                </div>
                <h4 className="text-white">Customizable Media Player: </h4>
                <p className="text-white">Enjoy streaming with a choice of over 10 different video player UIs, allowing you to tailor the viewing experience to your brand's unique style.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12">
              <div className="services-item text-center">
                <div className="icon">
                  <img src={payPerViewIcon} alt="Pay-Per-View Icon" className="icon-ima"/>
                </div>
                <h4 className="text-white">Affordable Pricing Options:</h4>
                <p className="text-white">Get started with Bizkranti's cost-effective plans that include seven built-in monetization models, maximizing your potential return on investment.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12">
              <div className="services-item text-center">
                <div className="icon">
                  <img src={newServiceIcon} alt="New Service Icon" className="icon-ima"/>
                </div>
                <h4 className="text-white">Enhanced Viewer Engagement: </h4>
                <p className="text-white">Use our flexible options and advanced analytics to create a more engaging experience for your audience, driving growth and increasing loyalty.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
